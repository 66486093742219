<template>
  <page class="main-container base-scrollbar">
    <page-section
      full-width
      bottom-border
    >
      <div
        class="flex justify-between flex-col md:flex-row"
        :class="{ 'mx-auto md:mx-20': isExternal }"
      >
        <div class="flex flex-row justify-center items-center">
          <page-title class="pr-2">
            <template #icon>
              <ic-audit />
            </template>
            Audit
          </page-title>
        </div>
        <div
          v-if="!shouldDisplayRunAuditButton"
          class="border shadow-bb-shadow rounded border-bb-dull-orange p-2 mx-4 w-full text-center bg-bb-warning-100"
        >
          <span class="font-bold">Audit completed successfully!</span> You have reached your limit, to generate a new
          Audit please
          <bid-link
            :router-link="false"
            primary
            target="_blank"
            href-link="https://brightbid.com/book-a-discovery-call/"
          >
            <template #text>contact sales</template> </bid-link
          >.
        </div>
        <div class="flex flex-row space-x-4 items-center m-auto md:mx-0 mt-2 md:mt-0">
          <merge-button-round
            v-if="!$route.params.id"
            class="run-audit-button"
            button-type="brand-purple"
            :disabled="isLoading || !!pendingAudit || !!auditsError || !shouldDisplayRunAuditButton"
            @click="generateNewAudit"
          >
            <p>Run Audit</p>
          </merge-button-round>
          <router-link
            v-if="!isExternal"
            :to="
              customerId
                ? { name: 'audit:external:history:details', params: { id: customerId } }
                : isExternal
                  ? { name: 'audit-report-history' }
                  : { name: 'site:reports:audit:history' }
            "
            class="flex flex-row gap-2 items-center cursor-pointer text-bb-text-default"
          >
            <ic-bullet-list :size="16" />
            <p>All audits</p>
          </router-link>
        </div>
      </div>
    </page-section>
    <div
      v-if="!latestCompletedAudit && pendingAudit && !auditsError && !isLoading"
      class="bg-white"
    >
      <div
        class="flex flex-col items-center py-8 bg-gradient-to-r from-neutral-0 to-purple-0 mx-10 rounded-2xl mt-6 px-4"
      >
        <SpinningBrightbidLogo :size="44" />
        <p class="h3">Audit in progress...</p>
        <p>Your audit is currently running, data are going to be available soon.</p>
        <div class="min-w-35 max-w-120 w-full mt-4">
          <progress-bar
            :value="pendingAuditProgress"
            :progress-mark="101"
            :show-percent-label="false"
            use-gradient
            container-height="12px"
            container-width="w-full"
            class="w-full"
          ></progress-bar>
        </div>
      </div>
    </div>
    <AuditLoader
      v-if="isLoading || (pendingAudit && !latestCompletedAudit && !auditsError)"
      class="max-w-screen-xl mx-auto"
      :class="{ 'mx-auto md:mx-28': isExternal }"
    />
    <div
      v-if="!isLoading && latestCompletedAudit && !auditsError"
      :key="latestCompletedAudit?.id"
      :class="{ 'mx-auto md:mx-28': isExternal }"
    >
      <div
        :class="{
          'bg-neutral-0': !isExternal,
          'bg-white': isExternal,
        }"
      >
        <div
          class="py-4 px-6 flex flex-col md:flex-row gap-1 w-full items-left md:items-center justify-between border-b border-neutral-50"
        >
          <p class="h4">{{ auditHeader }}</p>
          <p
            v-if="!customerId"
            class="p3 text-bb-neutral-gray font-medium"
          >
            Last audit run: {{ daysSinceLastCreatedAudit }}
            <span v-if="!!lastCompletedAuditDate"> - Compared to: {{ lastCompletedAuditDate }}</span>
          </p>
        </div>
      </div>
      <div
        v-if="latestCompletedAudit && pendingAudit && !auditsError && !isLoading"
        class="bg-white"
      >
        <div
          class="flex flex-col items-center py-8 bg-gradient-to-r from-neutral-0 to-purple-0 mx-10 rounded-2xl mt-6 px-4"
        >
          <SpinningBrightbidLogo :size="44" />
          <p class="h3">Audit in progress...</p>
          <p>Your audit is currently running, data are going to be available soon.</p>
          <div class="min-w-35 max-w-120 w-full mt-4">
            <progress-bar
              :value="pendingAuditProgress"
              :progress-mark="101"
              :show-percent-label="false"
              use-gradient
              container-height="12px"
              container-width="w-full"
              class="w-full"
            ></progress-bar>
          </div>
        </div>
      </div>
      <page-section
        full-width
        class="bg-white"
      >
        <AuditSummary
          :summary="auditSummary"
          :audit-details="auditDetails"
          :customer-id="customerId"
          :is-external="isExternal"
          @scrollToChecklist="scrollToChecklist"
          @scrollToCategory="scrollToCategory"
        />
      </page-section>

      <div class="bg-white z-10 border-b w-full px-6">
        <PageTabs
          class="bottom-0 border-neutral-50 max-w-screen-xl w-full mx-auto"
          :tabs="tabs"
          :selected-tab="selectedView"
        >
          <div
            v-for="(tab, index) in tabs"
            :key="tab.value"
            class="flex flex-row items-center w-full"
          >
            <div
              tabindex="0"
              class="cursor-pointer border-b-2 relative h5 flex justify-center w-full"
              :class="[
                selectedView === tab.value
                  ? 'border-bb-brand-purple text-bb-brand-purple font-medium'
                  : 'text-bb-neutral-gray border-white hover:border-neutral-50 hover:text-bb-text-headers duration-300 ease-linear',
              ]"
              @click="selectTab(tab.value)"
            >
              <div class="px-6 py-2 flex gap-2">
                <component :is="tab.icon" />
                <p>{{ tab.label }}</p>
              </div>
            </div>
            <div
              v-if="index < tabs.length - 1"
              class="border-l border-neutral-200 h-6 ml-5"
            ></div>
          </div>
        </PageTabs>
      </div>

      <page-section full-width>
        <transition
          :name="direction"
          mode="out-in"
        >
          <div
            :key="selectedView"
            class="max-w-screen-xl mx-auto"
          >
            <div v-if="selectedView === 'issues'">
              <AuditCheckList
                ref="AuditCheckList"
                :checks="auditDetails.checks"
                :is-external="isExternal"
                @openModal="selectSubcheck"
                @restoreCheck="restoreCheck"
              />
            </div>
            <div v-if="selectedView === 'categories'">
              <AuditCategories
                ref="AuditCategories"
                :checks="auditDetails.checks"
                :category-scores="auditSummary.score_by_category"
                @openModal="selectSubcheck"
              />
            </div>
          </div>
        </transition>
      </page-section>
    </div>
    <div v-if="auditsError && !isLoading">
      <div class="flex flex-col items-center text-center mt-20">
        <img
          src="/content/images/brightbid/bidbot-broken.svg"
          class="mb-6"
        />
        <p class="h3">Audit failed...</p>
        <p>Please try to refresh the page and start a new Audit.</p>
      </div>
    </div>

    <div
      v-if="!isLoading && !audits && !auditsError"
      class="flex flex-col justify-center mt-32"
    >
      <div class="flex flex-row w-full justify-between items-center max-w-215 mx-auto">
        <div class="w-full">
          <img src="/content/images/brightbid/audit-artwork.svg" />
        </div>
        <div class="flex flex-col my-16">
          <p class="h3 mb-4">Welcome to your Audits</p>
          <p>
            Start your first audit to receive an account score, detailed insights and actionable recommendations to
            enhance your performance!
          </p>
          <merge-button-round
            class="mr-auto mt-6"
            button-type="primary"
            @click="generateNewAudit"
          >
            <p>Run Audit</p>
          </merge-button-round>
        </div>
      </div>
    </div>
    <bb-base-modal
      v-if="showCheckModal || showDismissCheckModal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <AuditReportModal
          v-if="showCheckModal"
          :check-id="selectedSubcheck.id"
          :audit-id="latestCompletedAudit.id"
          :is-external="isExternal"
          :identification="identification"
          :brand-terms="brandTerms"
          :ignore-outside-click="ignoreOutsideClick"
          @dismiss-check="openDismissCheckModal"
          @close="closeModal"
        ></AuditReportModal>
        <DismissCheckModal
          v-if="showDismissCheckModal"
          :show-modal="showModal"
          :check-id="selectedSubcheck.id"
          :identification="identification"
          @checked="dontShowAgain"
          @back="backToCheckModal"
          @close="closeModal"
          @dismiss="handleDismiss"
        />
      </template>
    </bb-base-modal>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection'
import PageTabs from '../../components/shared/PageTabs.vue'
import IcAudit from '@/components/icon/brightbid/ic-audit.vue'
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import IcOutlinedSymbol from '@/components/icon/brightbid/ic-outlined-symbol.vue'
import IcBulletList from 'vue-material-design-icons/FormatListBulleted'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import AuditReportModal from '@/components/modals/AuditReportModal.vue'
import DismissCheckModal from '@/components/modals/forms/DismissCheckModal.vue'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import AuditCategories from '@/components/audit-components/AuditCategories.vue'
import AuditLoader from '@/components/audit-components/AuditLoader'
import AuditSummary from '@/components/audit-components/AuditSummary'
import AuditCheckList from '@/components/audit-components/AuditCheckList.vue'
import dayjs from 'dayjs'
import SpinningBrightbidLogo from '@/components/icon/brightbid/spinning-brightbid-logo.vue'
import ProgressBar from '@/components/shared/ProgressBar.vue'
import BidLink from '@/components/base/BidLink.vue'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'ReportsIndex',
  components: {
    Page,
    PageSection,
    PageTabs,
    IcAlertCircle,
    IcOutlinedSymbol,
    IcBulletList,
    BbBaseModal,
    AuditReportModal,
    DismissCheckModal,
    IcAudit,
    AuditLoader,
    AuditCategories,
    AuditSummary,
    AuditCheckList,
    SpinningBrightbidLogo,
    ProgressBar,
    BidLink,
    Toast,
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isRestoringCheck: false,
      selectedView: 'issues',
      ignoreOutsideClick: false,
      showCheckModal: false,
      showDismissCheckModal: false,
      dontShowAgainDismissedChecked: false,
      showModal: true,
      selectedSubcheck: null,
      polling: null, // Initialize polling in data
      direction: '', // For transition direction
      tabs: [
        { value: 'issues', label: 'Issues', icon: IcAlertCircle, index: 0 },
        { value: 'categories', label: 'Categories', icon: IcOutlinedSymbol, index: 1 },
      ],
      modalConfig: {
        width: null,
        height: null,
      },
    }
  },

  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
    ...mapState('audit', ['audits', 'auditDetails', 'auditSummary', 'auditsError', 'pendingAuditProgress']),
    ...mapGetters('audit', ['latestCompletedAudit', 'latestCreatedAudit', 'pendingAudit']),
    ...mapGetters('auth', ['userAuditGoogleCredentials']),
    customerId() {
      // Use the route parameter 'id' as the customerId
      return this.$route.params.id
    },
    auditHeader() {
      if (this.customerId) {
        return `Audit for ${this.latestCompletedAudit.google_customer_name}`
      }
      if (this.isExternal && this.userAuditGoogleCredentials) {
        return `${this.userAuditGoogleCredentials.googleCustomerName + ' Audit #' + this.latestCompletedAudit.auditNumber}`
      } else if (this.selectedSite) {
        return `${this.selectedSite.label + ' Audit #' + this.latestCompletedAudit.auditNumber}`
      }
      return 'No audits available'
    },

    daysSinceLastCreatedAudit() {
      if (!this.latestCreatedAudit) {
        return null
      }

      const auditDate = dayjs(this.auditSummary?.current?.date)
      const today = dayjs()

      if (auditDate && today) {
        if (today.isSame(auditDate, 'day')) {
          return 'today'
        } else if (today.subtract(1, 'day').isSame(auditDate, 'day')) {
          return 'yesterday'
        } else {
          const dayDiff = today.diff(auditDate, 'day')
          return `${dayDiff} days ago`
        }
      } else {
        return 'Audit date not available'
      }
    },

    lastCompletedAuditDate() {
      if (!this.auditSummary?.previous?.date) {
        return null
      }
      return dayjs(this.auditSummary.previous.date).format('MM/DD/YYYY')
    },
    amountOfCompletedAudits() {
      if (!this.audits) {
        return 0
      }

      return this.audits.filter(audit => audit.status === 'completed').length
    },
    identification() {
      if (this.customerId) {
        return this.customerId // Use customerId if provided
      }
      return this.isExternal ? this.userAuditGoogleCredentials.googleCustomerId : this.selectedSite?.value
    },
    shouldDisplayRunAuditButton() {
      if (!this.latestCompletedAudit) {
        return true
      }

      const auditReleaseDate = dayjs(process.env.VUE_AUDIT_RELEASE_DATE || '2025-02-04 12:00:00')
      const auditDate = dayjs(this.latestCompletedAudit?.created_at)

      // Hide Run Audit button for External customers that already have one completed audit after the release date
      return !(this.isExternal && this.latestCompletedAudit && auditDate.isAfter(auditReleaseDate))
    },
  },
  async mounted() {
    this.isLoading = true
    const startTime = Date.now()

    // This will check local storage for the checkbox state
    const dontShowAgainDismissedChecked = localStorage.getItem('dontShowAgainDismissedChecked')
    if (dontShowAgainDismissedChecked !== null) {
      this.dontShowAgainDismissedChecked = JSON.parse(dontShowAgainDismissedChecked)
    }

    // Don't fetch site if external or customerId is provided
    if (!this.isExternal && !this.customerId) {
      await this.fetchSiteDetails(this.selectedSite.value)
    }

    // Fetch audit data
    await this.fetchAuditData()

    // Only start polling if we have a pending audit
    if (this.pendingAudit) {
      this.pollAuditProgress(this.pendingAudit.id)
    }

    const fetchTime = Date.now() - startTime
    const remainingTime = Math.max(1000 - fetchTime, 0)

    setTimeout(() => {
      this.isLoading = false
    }, remainingTime)
  },
  beforeDestroy() {
    if (this.polling) {
      clearInterval(this.polling)
      this.polling = null
    }
  },
  methods: {
    ...mapActions('audit', [
      'fetchAudits',
      'fetchAuditDetails',
      'fetchAuditSummary',
      'fetchExternalAudits',
      'fetchExternalAuditDetails',
      'fetchExternalAuditSummary',
      'fetchPendingAuditProgress',
      'fetchPendingExternalAuditProgress',
      'dismissAuditCheck',
      'undoDismissAuditCheck',
      'updateChecks',
    ]),
    ...mapActions('site', ['fetchSiteDetails']),
    ...mapMutations('audit', ['SET_AUDIT_CHECKS']),
    closeModal() {
      this.showCheckModal = false
      this.showDismissCheckModal = false
    },
    openDismissCheckModal() {
      if (this.dontShowAgainDismissedChecked) {
        return this.handleDismiss()
      }
      this.modalConfig.width = '600px'
      this.modalConfig.height = '394px'
      this.showCheckModal = false
      this.showDismissCheckModal = true
    },
    async handleDismiss() {
      try {
        this.restoredCheck = { ...this.selectedSubcheck }
        await this.dismissAuditCheck({
          siteId: this.identification,
          report_id: this.latestCompletedAudit.id,
          check_id: this.selectedSubcheck.id,
        })
        this.closeModal()
        // Update the Vuex store to reflect the dismissed check
        await this.fetchAuditData()
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success!',
            message: 'The check was successfully dismissed.',
            type: 'success',
            hasAButton: true,
            buttonText: 'Go to dismissed checks.',
            buttonType: 'tertiary',
            onClick: this.handleToastButtonClick,
          },
        })
      } catch (error) {
        console.error('Error dismissing check:', error)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to dismiss the check. Please try again.',
            type: 'error',
          },
        })
      }
    },
    async restoreCheck(item) {
      try {
        this.isRestoringCheck = true
        this.isLoading = true

        const auditId = this.auditDetails?.id
        if (!auditId) {
          throw new Error('No audit ID found')
        }
        const response = await this.undoDismissAuditCheck({
          siteId: this.identification,
          report_id: auditId,
          check_id: item.id,
        })

        if (response?.status === 200 && response?.data) {
          // Restore the full check from the stored object
          const restoredCheck = this.restoredCheck
          const updatedChecks = this.auditDetails.checks.map(check => {
            if (check.id === item.id) {
              // Restore the full object properties
              return { ...check, ...restoredCheck }
            }
            return check
          })

          // This will update Vuex store with the full restored check
          this.SET_AUDIT_CHECKS(updatedChecks)
          this.updateChecks(updatedChecks)
          this.closeModal()

          // Fetch audit data
          await this.fetchAuditData()

          // Only start polling if we have a pending audit
          if (this.pendingAudit) {
            this.pollAuditProgress(this.pendingAudit.id)
          }

          await this.loadToast({
            title: 'Success!',
            message: 'The check has been successfully restored. Changes may take a few seconds to update.',
            type: 'success',
          })
        } else {
          console.warn('Unexpected API response format:', response)
          throw new Error('Unexpected API response format')
        }
      } catch (error) {
        console.error('Error restoring check:', error)

        await this.loadToast({
          title: 'Error',
          message: 'Failed to restore the check. Please try again.',
          type: 'error',
        })
      } finally {
        this.isRestoringCheck = false
      }
    },
    selectTab(value) {
      const newIndex = this.tabs.findIndex(t => t.value === value)
      const currentIndex = this.tabs.findIndex(t => t.value === this.selectedView)
      this.direction = newIndex > currentIndex ? 'tab-transition-left' : 'tab-transition-right'

      this.selectedView = value
      this.$emit('select-tab', value)
    },
    backToCheckModal() {
      // Ignore outsideclick so the modal doesn't close
      this.ignoreOutsideClick = true

      this.selectSubcheck(this.selectedSubcheck)
      this.showDismissCheckModal = false
      this.showCheckModal = true
    },
    dontShowAgain() {
      this.dontShowAgainDismissedChecked = !this.dontShowAgainDismissedChecked
      localStorage.setItem('dontShowAgainDismissedChecked', this.dontShowAgainDismissedChecked)
    },
    selectSubcheck(item) {
      if (this.isRestoringCheck) {
        return
      }
      this.selectedSubcheck = item
      this.brandTerms =
        this.isExternal || this.customerId || !item.title.toLowerCase().includes('brand')
          ? []
          : this.siteDetails.brand_terms

      const isPassed = item.severity === 'passed'
      const isSkipped = item.status === 'skipped'

      if (isPassed || isSkipped) {
        this.modalConfig.width = '600px'
        this.modalConfig.height = '600px'
      } else {
        this.modalConfig.width = '90vw'
        this.modalConfig.height = '90vh'
      }

      this.showCheckModal = true
    },
    ...mapActions('toast', ['loadToast']),

    async fetchAuditData() {
      if (this.isExternal || this.customerId) {
        await this.fetchExternalAudits({ customerId: this.identification })

        if (this.latestCompletedAudit) {
          await this.fetchExternalAuditDetails({
            customerId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
          await this.fetchExternalAuditSummary({
            customerId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
        }

        if (this.pendingAudit) {
          await this.fetchPendingExternalAuditProgress({
            customerId: this.identification,
            auditReportId: this.pendingAudit.id,
          })
        }
      } else {
        await this.fetchAudits({ siteId: this.identification })

        if (this.latestCompletedAudit) {
          await this.fetchAuditDetails({
            siteId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
          await this.fetchAuditSummary({
            siteId: this.identification,
            auditReportId: this.latestCompletedAudit.id,
          })
        }

        if (this.pendingAudit) {
          await this.fetchPendingAuditProgress({
            siteId: this.identification,
            auditReportId: this.pendingAudit.id,
          })
        }
      }
      if (this.pendingAudit) {
        this.pollAuditProgress(this.pendingAudit.id)
      } else {
        // No pending audit, reset progress and status
        this.$store.commit('audit/SET_PENDING_AUDIT_PROGRESS', 0)
        this.$store.commit('audit/SET_PENDING_AUDIT_STATUS', '')
        if (this.polling) {
          clearInterval(this.polling)
          this.polling = null
        }
      }
    },
    async generateNewAudit() {
      const url =
        this.isExternal || this.customerId
          ? `/audit/customer/${this.identification}/report`
          : `/audit/site/${this.identification}/report`

      // Determine the payload based on the conditions
      const payload = this.customerId
        ? { google_customer_name: this.latestCompletedAudit?.google_customer_name }
        : this.isExternal
          ? { google_customer_name: this.userAuditGoogleCredentials?.googleCustomerName }
          : {}

      try {
        await this.$http.post(url, payload)
        await this.loadToast({
          title: 'Success!',
          message: 'A new audit is being generated.',
          type: 'success',
        })
      } catch (error) {
        await this.loadToast({
          title: 'Error',
          message: 'Audit failed to generate. Please try again.',
          type: 'error',
        })
      }

      this.fetchAuditData()
    },
    scrollToChecklist(severity) {
      if (this.selectedView === 'issues') {
        this.$refs.AuditCheckList.scrollToSeverity(severity)
      }
      if (this.selectedView === 'categories') {
        setTimeout(() => {
          this.$refs.AuditCheckList.scrollToSeverity(severity)
        }, 200)
        this.selectedView = 'issues'
      }
    },

    scrollToCategory(category) {
      if (this.selectedView === 'categories') {
        this.$refs.AuditCategories.scrollToSection(category)
      }
      if (this.selectedView === 'issues') {
        setTimeout(() => {
          this.$refs.AuditCategories.scrollToSection(category)
        }, 200)
        this.selectedView = 'categories'
      }
    },
    async pollAuditProgress(auditId) {
      if (this.polling) {
        clearInterval(this.polling)
      }

      const interval = 3000

      this.polling = setInterval(async () => {
        try {
          if (this.isExternal || this.customerId) {
            await this.fetchPendingExternalAuditProgress({
              customerId: this.identification,
              auditReportId: auditId,
            })
          } else {
            await this.fetchPendingAuditProgress({
              siteId: this.identification,
              auditReportId: auditId,
            })
          }

          // Check if the pending audit has completed
          if (!this.pendingAudit) {
            clearInterval(this.polling)
            this.polling = null

            this.isLoading = true

            const startTime = Date.now()

            // Re-fetch audit data to update with the new completed audit
            await this.fetchAuditData()
            const fetchTime = Date.now() - startTime
            const remainingTime = Math.max(1000 - fetchTime, 0)

            setTimeout(() => {
              this.isLoading = false
            }, remainingTime)
          }
        } catch (error) {
          clearInterval(this.polling)
          this.polling = null
        }
      }, interval)
    },
    handleToastButtonClick() {
      const severity = 'disabled'
      if (this.selectedView === 'issues') {
        this.$refs.AuditCheckList.scrollToSeverity(severity)
      }
      if (this.selectedView === 'categories') {
        setTimeout(() => {
          this.$refs.AuditCheckList.scrollToSeverity(severity)
        }, 200)
        this.selectedView = 'issues'
      }
    },
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}

.run-audit-button {
  white-space: nowrap;
}
</style>
