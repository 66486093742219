<template>
  <div
    key="subCheck"
    class="p-8"
  >
    <div
      class="flex flex-row items-center gap-x-2 cursor-pointer"
      @click="back"
    >
      <ic-arrow :size="12" />
      <p>Back</p>
    </div>
    <div class="flex flex-col py-6">
      <h4 class="h4">Dismiss "{{ subCheck?.title }}"?</h4>
      <p class="pt-4">
        Dismissing this check will remove it from this Audit and the score will be recalculated accordingly. This check
        will not run again on this account.
        <span class="block pt-4">
          You can find all dismissed checks at the bottom of the Issues tab, where you can reactivate them if needed.
        </span>
      </p>
    </div>
    <div class="flex flex-row items-center gap-x-2 text-black">
      <check-box
        v-model="dismissCheck"
        :input-name="subCheck?.title"
        :input-id="checkId"
        @input="onDismissCheckChanged"
      />
      <p>Don't show this again</p>
    </div>
    <div class="flex flex-row justify-between gap-x-6">
      <merge-button-round
        v-for="button in actionButtons"
        :key="button.label"
        :button-type="button.type"
        class="min-w-45 h-9.5 mt-5 sm:mt-10"
        @click="button.onClick"
      >
        <p>{{ button.label }}</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
//VUEX
import { mapActions, mapGetters } from 'vuex'

//COMPONENTS
import CheckBox from '@/components/input/brightbid/CheckBox'
import MergeButtonRound from '@/components/btn/MergeButtonRound'

//ICONS
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'

export default {
  name: 'DismissCheckModal',
  components: { CheckBox, MergeButtonRound, IcArrow },
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    identification: {
      type: Number,
      required: true,
    },
    checkId: {
      type: String,
      required: true,
    },
  },
  data() {
    return { dismissCheck: false }
  },
  computed: {
    ...mapGetters('auditCheck', { subCheck: 'getCheckDetails' }),
    actionButtons() {
      return [
        {
          label: 'Cancel',
          type: 'secondary',
          onClick: () => this.close(),
        },
        {
          label: 'Dismiss',
          type: 'primary',
          onClick: () => this.dismiss(),
        },
      ]
    },
  },

  methods: {
    ...mapActions('auditCheck', ['fetchCheckDetails', 'fetchExternalCheckDetails']),

    onDismissCheckChanged() {
      this.$emit('update:showModal', !this.dismissCheck)
      if (this.dismissCheck) {
        this.$emit('checked')
      }
    },
    back() {
      this.$emit('back')
    },
    close() {
      this.$emit('close')
    },
    dismiss() {
      this.$emit('dismiss')
    },
    //Fetches the check details
    async fetchAuditCheckData() {
      const { identification, checkId } = this.$route.params

      if (this.isExternal || identification) {
        await this.fetchExternalCheckDetails({
          customerId: identification,
          checkId: checkId,
        })
      } else {
        await this.fetchCheckDetails({
          siteId: identification,
          checkId: checkId,
        })
      }
    },
  },
}
</script>
